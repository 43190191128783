import React from "react"
import { LocalizedLink as Link } from "gatsby-theme-i18n"

export default function Banner() {
  return (
    <div data-nosnippet>
      {/*Here you can set an announcement, that will be presented on top of every blog post though PostLayout.js. There are two versions a simple one grey text on white background and a more noticeable one with a blue background. Comment out the one you dont want to use.*/}

      {/* extend this comment to hide announcement-simple
      <a href="https://www.am.ai/en/career/big-data-engineer/">
        <div id="announcement-simple">
          We are hiring! AMAI is looking for German-speaking Data Engineers to join our team. <u>Apply here.</u>
        </div>
      </a>
      */}

      {/* extend this comment to hide announcement-blue */}
      <Link to="/career/data-scientist/">
        <div id="announcement-blue" className="d-block d-md-none">
          German-speaking Data Scientists wanted! Join an amazing team in Karlsruhe or Remote. <u style={{ color: "#BDDDEF" }}>Apply here.</u>
        </div>
        <div id="announcement-blue" className="d-none d-md-block">
          German-speaking Data Scientists wanted! <br />Join an amazing team in Karlsruhe or Remote. <u style={{ color: "#BDDDEF" }}>Apply here.</u>
        </div>
      </Link>

      <style type="text/css">
        {`
          #announcement-blue{
            border-radius: 10px; 
            padding: 20px;
            margin-bottom: 40px;
            cursor: pointer;

            -webkit-box-shadow: 0 20px 20px -16px #bebeee;
            box-shadow: 0 20px 20px -16px #bebeee; 
            
            color: white;
            background: rgb(53,134,164); 
            background: linear-gradient(170deg, rgba(53,134,164,1) 0%, rgba(32,98,135,1) 44%, rgba(35,97,134,1) 69%, rgba(20,55,86,1) 99%);

            moz-transition: all .4s ease-in-out;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out; 
          }

          #announcement-blue p {
            color: white;
          }

          #announcement-blue:hover {
            -webkit-box-shadow: 0 30px 20px -16px #bebeee;
            box-shadow: 0 30px 20px -16px #bebeee; 
          }

          #announcement-simple{
            border-radius: 0px; 
            padding: 20px;
            margin-bottom: 30px;
            cursor: pointer;

            -webkit-box-shadow: 0 2px 20px -16px #dedede;
            box-shadow: 0 2px 20px -16px #dedede; 

            border-bottom: 1px solid #D3D3D3;
        `}
      </style>
    </div>
  )
}
