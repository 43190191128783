import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import PageWrapper from "../PageWrapper"
import { Section, Title, Text, Box } from "../Core"
import { t, plural } from "@lingui/macro"

import PostDetails from "../../sections/blog/PostDetails"
import SEO from "../SEO"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import shortcodes from "../../components/MdxShortcodes"
import BlogList from "../../sections/blog/BlogList"
//import "katex/dist/katex.min.css";
import "prismjs/themes/prism-okaidia.css"
import JsonLd from "../SEO/jsonld"

import Banner from "../../components/Banner"

const PostLayout = ({ data: { localized, byId, latest }, location }) => {
  const isLocalized = localized !== null
  const mdx = isLocalized ? localized : byId
  return (
    <>
      <SEO
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description || mdx.excerpt || ""}
        image={
          mdx.frontmatter.banner
            ? mdx.frontmatter.banner.childImageSharp.gatsbyImageData.images
                .fallback.src
            : ""
        } // TODO: Why is banner null?
        pathname={location.pathname}
        article
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          headline: mdx.frontmatter.title,
          image: [
            mdx.frontmatter.banner
              ? mdx.frontmatter.banner.childImageSharp.gatsbyImageData.images
                  .fallback.src
              : "",
          ],
          datePublished: mdx.frontmatter.dateISO,
          dateModified: new Date().toISOString(),
        }}
      />
      <PageWrapper footerDark>
        <Section className="!tw-pb-0">
          <div className="tw-pt-12"></div>
          <Container>
            <Row className="tw-justify-center">
              <Col lg="12">
                <div className="tracking-tighter tw-text-5xl md:tw-text-7xl tw-pb-12 tw-text-black md:tw-text-center">
                  {mdx.frontmatter.title}
                </div>
                <Box className="tw-flex tw-text-center tw-justify-left tw-leading-6 md:tw-justify-center tw-text-lg tw-text-gray-500 ">
                  <div className="tw-mr-8">{mdx.frontmatter.date}</div>
                  <div className="tw-mr-8">
                    {plural(mdx.timeToRead, { one: "# Minute", other: "# Minuten" })}
                  </div>
                  <div>{mdx.frontmatter.author}</div>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="!tw-pb-0">
          <Container>
            <Row className="md:tw-justify-center">
              <Col lg="8" className="tw-mb-12">
                <Banner />
                <PostDetails tags={mdx.frontmatter.tags}>
                  <MDXProvider components={shortcodes}>
                    <MDXRenderer>{mdx.body}</MDXRenderer>
                  </MDXProvider>
                </PostDetails>
              </Col>
              {/*<Col lg="4" className="">
                <Sidebar />
  </Col>*/}
            </Row>
          </Container>
        </Section>
        <Section className="!tw-pb-0">
          <Col lg="12" className="tw-text-center tw-mb-12">
            <Title>{t`Weiterlesen`}</Title>
          </Col>
          <BlogList
            data={latest}
            pageContext={{ currentPage: 0, numPages: 1 }}
          />
        </Section>
      </PageWrapper>
    </>
  )
}

export const pageQuery = graphql`
  fragment postQueryFragment on Mdx {
    id
    body
    excerpt(pruneLength: 200, truncate: true)
    timeToRead
    fields {
      slug
    }
    frontmatter {
      title
      tags
      date(formatString: "DD MMM, YYYY")
      dateISO: date(formatString: "YYYY-MM-DD")
      author
      banner {
        absolutePath
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            aspectRatio: 2
            placeholder: BLURRED
          )
        }
      }
    }
  }
  query($locale: String!, $slug: String!, $id: String!) {
    localized: mdx(fields: { locale: { eq: $locale }, slug: { eq: $slug } }) {
      ...postQueryFragment
    }
    byId: mdx(id: { eq: $id }) {
      ...postQueryFragment
    }
    latest: allMdx(
      filter: {
        fields: {
          sourceInstanceName: { eq: "posts" }
          locale: { eq: $locale }
          slug: { ne: $slug }
        }
        frontmatter: { date: { ne: null }, status: { eq: "published" } }
        id: { ne: $id }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          ...postQueryFragment
        }
      }
    }
  }
`

export default PostLayout
